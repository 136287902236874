<template>
  <div class="router">
    <!--    <div class="router-info">-->
    <!--      <div class="router-info__create">-->
    <!--        <base-button @click="attachSubnet(tariff)">-->
    <!--          {{ $t('addSubnet') }}-->
    <!--        </base-button>-->
    <!--      </div>-->
    <!--    </div>-->
    <transition name="bubble">
      <page-block class="table">
        <tariffs-table-router-view :dataset="ports" :router="routerId" />
      </page-block>
    </transition>
  </div>
</template>

<script>
import { OPTariff } from '@/models/OP/OPTariff';
import providerChange from '@/mixins/providerChange';
import TariffsTableRouterView from '@/layouts/Stack/pages/Main/components/TariffsTableRouterView';
import attachSubnet from '@/layouts/Stack/mixins/attachSubnet';
export default {
  name: 'RouterView',
  components: {
    TariffsTableRouterView,
  },
  mixins: [providerChange, attachSubnet],
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof OPTariff,
    },
  },
  data() {
    return {
      // isLoading: true,
    };
  },
  computed: {
    routerId() {
      return this.tariff.id;
    },
    subnets() {
      return this.$store.state.moduleStack.subnets.filter(x => x.network_id === this.tariff.id);
    },
    ports() {
      return this.$store.getters['moduleStack/GET_SORTED_LIST_ROUTER'].filter(
        x => x.device_id === this.routerId
      );
    },
    currentPort() {
      return this.$store.state.moduleStack.currentPort.ports
        ? this.$store.state.moduleStack.currentPort.ports
        : null;
    },
  },
  mounted() {
    this.getPort(this.tariff.id);
  },
  methods: {
    getPort(id) {
      return this.$store.dispatch('moduleStack/getPort', id);
    },
    async onProviderChange() {
      await this.$store.dispatch('moduleStack/reset', 16);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "addSubnet": "Привязать подсеть",
      "sure": {
        "confirm": "Подключить",
        "success": "Подсеть добавлена успешно.",
        "fail": "Что-то пошло не так",
        "close": "Закрыть"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.table {
  margin-top: 0.5rem;
}
.router {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;

  &-info {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb, center);
    }
    &__create {
      display: flex;
      flex-direction row;
      align-content flex-end;
      align-items baseline;
      margin-top: 1.5rem;
      max-width: 200px;
      &-btn {
        margin-top: 20px
        margin-right: 0;
        +breakpoint(ms-and-up) {
          margin-top: 20px;
        }
      }
      &-hint {
        margin: -0.25rem 0.25rem;
        vertical-align: middle;
      }

      +breakpoint(sm-and-up) {
        margin: 0 0 0 auto;
      }
    }
    //&__create {
    //  flex: 0 0 auto;
    //  margin-top: 0.5rem;
    //  max-width: 160px;
    //
    //  +breakpoint(sm-and-up) {
    //    margin: 0 0 0 auto;
    //  }
    //}
  }

}
</style>
